<template>
    <div class="contents allVod">
        <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-menu-schedule-history") }}</h1>
        <div class="box one filter m-b-20" style="display: flex;justify-content: flex-start;align-items: center;">
            <div class="flex">
                <p class="bold">{{ $t("search-keyword-title") }}</p>
                <select v-model="keywordType">
                    <option value="all" selected>{{ $t("search-keyword-all") }}</option>
                    <option value="stationName">{{ $t("station-data-name") }}</option>
                    <option value="scheduleName">{{ $t("station-data-schedule-title") }}</option>
                    <option value="departmentName">{{ $t("device-data-manager-dept-name") }}</option>
                    <option value="userName">{{ $t("device-data-manager-operator") }}</option>
                </select>
                <input type="text" v-model="keyword" @keydown.enter="search" />
            </div>
            <div class="flexC">
                <p class="bold">{{ $t("search-regdate-title") }}</p>
                <Datepicker v-model="startYmd" :language="ko" :format="customFormatter" placeholder="YYYY-MM-DD">
                </Datepicker>
                <span>~</span>
                <Datepicker v-model="endYmd" :language="ko" :format="customFormatter"
                    :disabled-dates="{ to: moment(startYmd).toDate() }" placeholder="YYYY-MM-DD"></Datepicker>
            </div>
            <div class="buttonWrap" style="display: flex; margin-top: 0;">
                <button class="point medium" @click="search"
                    style="width: 120px; margin-left: 20px;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
                    {{ $t("btn-searching") }}
                </button>
                <button class="medium margin6" @click="reset"
                    style="width: 120px;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">{{
                        $t("btn-reset") }}</button>
            </div>
        </div>
        <div class="sh-table content-box">
            <h2>스케줄 이력 목록</h2>
            <div class="tableWrap box" style="width: 100%; height: auto; min-height: 558px; border: none; padding: 0;">
                <div class="tableBox">
                    <div style="min-height: 528px;">
                        <table style="table-layout: fixed;">
                            <colgroup>
                                <col style="width: 4%;" />
                                <col style="width: 14%;" />
                                <col style="width: 20%;" />
                                <col style="width: 14%;" />
                                <col style="width: 10%;" />
                                <col style="width: 10%;" />
                                <col style="width: 10%;" />
                                <col style="width: 12%;" />
                                <col style="width: 5%;" />
                                <col style="width: 15%;" />
                            </colgroup>
                            <tr>
                                <th>No</th>
                                <th>{{ $t("station-data-name") }}</th><!--스테이션명-->
                                <th>{{ $t("station-data-schedule-title") }}</th><!--스케줄 제목-->
                                <th>{{ $t("station-data-mission-type") }}</th><!--미션타입-->
                                <th>{{ $t("station-data-flight-type") }}</th><!--비행타입-->
                                <th>{{ $t("device-data-request") }}</th><!--요청상태-->
                                <th>{{ $t("device-data-work") }}</th><!--작업상태-->
                                <th>{{ $t("device-data-manager-dept-name") }}</th><!--소속-->
                                <th>{{ $t("device-data-manager-operator") }}</th><!--조종사-->
                                <th>{{ $t("station-data-flight-time") }}</th><!--비행시간-->
                            </tr>
                            <tr v-for="(history, index) in scheduleHistory" :key="index"
                                @click="moveToDetailPage(history.scheduleItemLogId)" class="station-data-tr">
                                <td>{{ index+(currentPage-1)*10 }}</td>
                                <td>{{history.deviceName}}</td>
                                <td>{{ history.scheduleName }}</td>
                                <td>{{ handleScheduleType(history.scheduleType) }}</td>
                                <td>{{ handleMissionType(history.missionType) }}</td>
                                <td>{{ handleRequestStatus(history.requestStatus) }}</td>
                                <td>{{ handleRequestStatus(history.workJobStatus) }}</td>
                                <td>{{ history.managerDepartmentName }}</td>
                                <td>{{ history.managerUserName }}</td>
                                <td>{{ history.runLocalDate +' '+ history.runLocalTime }}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="pagination">
                        <el-pagination small layout="prev, pager, next" 
                            :total="total" 
                            :page-size="size"
                            :current-page="currentPage" 
                            @prev-click="handleCurrentChange(-1)"
                            @next-click="handleCurrentChange(1)" 
                            @current-change="moveToSelectPage">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.title {
    display: none;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-b-5 {
    margin-bottom: 5px;
}

.content-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.content-box {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.sh-table>h2 {
    font-size: 1.4rem;
    font-weight: bold;
}

.station-data-tr {
    cursor: pointer;
}
</style>

<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";

import Datepicker from "vuejs-datepicker";
import { fetchScheduleHistory } from "@/api/station/stationHistory";

export default {
    components: { Datepicker },
    name: "ScheduleHistory",
    data() {
        return {
            moment: moment,
            ko: ko,
            startYmd: null,
            endYmd: null,
            keywordType: null,
            keyword: null,
            currentPage: null, // 요청할 페이지
            scheduleHistory: [], //최대 10개씩 보여줌
            size: 10, // 데이터 몇개씩 요청보낼지
            total: 45
            
        };
    },
    created() {
        this.initData()
        this.getScheduleList();
    },
    methods: {
        initData() {
            this.keywordType = this.$route.query.keywordType || 'all'
            this.keyword = this.$route.query.keyword || ''
            this.currentPage = Number(this.$route.query.page) || 1

            if (this.$route.query.startYmd === undefined || this.$route.query.startYmd === null) {
                this.startYmd = null
            } else {
                this.startYmd = this.$route.query.startYmd
            }

            if (this.$route.query.endYmd === undefined || this.$route.query.endYmd === null) {
                this.endYmd = null
            } else {
                this.endYmd = this.$route.query.endYmd
            }

        },

        /**
         * 검색
         * query로 키워드, 날짜등을 전달하여 페이지이동함
         */
        search() {
            if (this.startYmd === null || this.startYmd === undefined || this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
                return alert(this.$t("alert-message-check-regdate"));
            }
            this.$router.push({
                name: `ScheduleHistory`,
                query: {
                    page: 1,
                    startYmd: moment(this.startYmd).format("YYYY-MM-DD"),
                    endYmd: moment(this.endYmd).format("YYYY-MM-DD"),
                    keyword: this.keyword,
                    keywordType: this.keywordType
                },
            })
        },

        handleRequestStatus(requestStatus) {
            if(requestStatus === undefined ||requestStatus === 'Failed' || requestStatus === null) {
                return '요청실패'
            }
            return '요청성공'
        },
        /**
         * 페이지네이션 prev, next 이동
         * @param number prev:-1, next: +1
         */
        handleCurrentChange(number) {
            this.$router.push({
                name: `ScheduleHistory`,
                query: { 
                    page: this.currentPage + number,
                    startYmd: moment(this.startYmd).format("YYYY-MM-DD"),
                    endYmd: moment(this.endYmd).format("YYYY-MM-DD"),
                }
            })
        },

        /**
         * 선택한 페이지로 이동
         * @param pageNumber 선택한 페이지번호
         */
        moveToSelectPage(pageNumber) {
            this.$router.push({
                name: `ScheduleHistory`,
                query: { 
                    page: pageNumber,
                    keyword: this.keyword,
                    keywordType: this.keywordType,
                    startYmd: moment(this.startYmd).format("YYYY-MM-DD"),
                    endYmd: moment(this.endYmd).format("YYYY-MM-DD"),
                }
            })
        },

        /**
         * 스케줄이력 디테일페이지 이동
         */
        moveToDetailPage(scheduleItemLogId) {
            this.$router.push({
                name: 'ScheduleHistoryDetail',
                params: { scheduleItemLogId }

            });
        },

        /**
         * 리셋. 처음 1페이지도 이동시 리셋
         */
        reset() {
            this.$router.push({
                name: `ScheduleHistory`,
                query: {
                    page: 1,
                }
            })
        },
        customFormatter(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        
        /**
         * 스케줄 이력 GET
         */
        getScheduleList() {
            const params = {
                refId: this.eventId,
                page_page: this.currentPage-1,
                page_size: this.size,
                keywordType: this.keywordType,
                keyword: this.keyword,
                beginDate: this.startYmd,
                endDate: this.endYmd,
                refType: "None",
            };
            
            fetchScheduleHistory(params).then((res)=> {
                console.log(res)
                this.scheduleHistory = res.data.data.content
                this.total = res.data.data.totalElements
            })
        },

        handleMissionType(responseType) {
            if (responseType === 'Mapping') {
                return '매핑'
            } else if (responseType === 'WayPoint') {
                return '웨이포인트';
            } 
            return responseType;  
        },

        handleScheduleType(responseType) {
            if (responseType === 'Immediately') {
                return '즉시비행'
            } else if (responseType === 'Reserve') {
                return '예약비행';
            } else if(responseType === 'Period'){
                return '반복비행'; 
            } 
            return "왕벌의 비행"
        }

    },

};
</script>
